import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteUser,
  getAPI,
  getAllUsers,
  putAPI,
} from "../../services/User/UserService";

import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { use } from "react";

const validationSchema = Yup.object({
  simpleZapperAmount: Yup.number()
    .required("Required")
    .min(1, "Amount must be at least 1")
    .max(30, "Amount cannot be more than 30"),
  topZapperAmount: Yup.number()
    .required("Required")
    .min(1, "Amount must be at least 1")
    .max(30, "Amount cannot be more than 30"),
});

const ZapperList = (props) => {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("Zapper");
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const history = useHistory();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      // const response =
      //   props.ratings && props.ratings.length > 0
      //     ? await getAllUsers(currentPage, limit, search, type, props.ratings)
      //     : await getAllUsers(currentPage, limit, search, type);
      const response =
        props.minRate !== null && props.maxRate !== null
          ? await getAllUsers(
              currentPage,
              limit,
              search,
              type,
              props.minRate,
              props.maxRate
            )
          : await getAllUsers(currentPage, limit, search, type);
      setUsers(response.data.data.users);
      const total = response.data.data?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.total);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      const payload = {
        simpleZapperAmount: values?.simpleZapperAmount,
        topZapperAmount: values?.topZapperAmount,
        zapperId: selectedRowId,
      };
      const res = await putAPI("/admin/setZappersAmount", payload);
      toast.success("Platform fee updated successfully");
      getTableData();
      setSubmitting(false);
      setShowModal(false);
    } catch (err) {
      console.error("Update failed", err);
    } finally {
      setLoading(false);
    }
  };

  ///function to delete data ///
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      getTableData();
      notifyTopRight("Deleted Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.error(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
    } catch (error) {
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, search, props.minRate, props.maxRate]);

  return (
    <>
      <div className="d-block justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-6">
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <Table responsive>
          <thead style={{ color: "black" }}>
            <tr>
              <th>
                <strong>NAME</strong>
              </th>
              <th>
                <strong>EMAIL</strong>
              </th>

              <th>
                <strong>PHONE NUMBER</strong>
              </th>

              <th>
                <strong>RATINGS</strong>
              </th>
              <th>
                <strong>Simple Zapper Amount</strong>
              </th>
              <th>
                <strong>Top Zapper Amount</strong>
              </th>

              <th>
                <strong>STATUS</strong>
              </th>
              <th>
                <strong>created at</strong>
              </th>
              <th>
                <strong>PLATFORM FEE</strong>
              </th>
              <th>
                <strong>ACTION</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item, i) => (
              <tr key={i} className={item.isDeleted ? "disabled-row" : ""}>
                <td>{item?.name}</td>
                <td>{item?.email}</td>

                <td>
                  {item.countryCode && "+" + item.countryCode}{" "}
                  {item?.phoneNumber}
                </td>

                <td style={{ textAlign: "center" }}>
                  {item?.rating?.toFixed(1)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {item?.simpleZapperAmount}
                </td>
                <td style={{ textAlign: "center" }}>{item?.topZapperAmount}</td>

                <td>
                  {item?.isDeleted ? (
                    <Badge bg="" className="badge-danger">
                      Deleted
                    </Badge>
                  ) : (
                    <Badge bg="" className="badge-success">
                      Enabled
                    </Badge>
                  )}
                </td>

                <td>{moment(item?.createdAt).format("MMM Do YYYY, h:mm A")}</td>

                <td>
                  <Button
                    variant="primary"
                    style={{ padding: "10px" }}
                    onClick={() => {
                      setSelectedRowId(item._id);
                      setSelectedUser(item);
                      setShowModal(true);
                    }}
                  >
                    Change Platform Fee
                  </Button>
                </td>

                <td>
                  {!item?.isDeleted && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="dark light"
                        className="light sharp btn btn-dark i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/zapper-details",
                              state: item,
                            })
                          }
                        >
                          Details
                        </Dropdown.Item>
                        {item.isBlocked ? (
                          <Dropdown.Item onClick={() => onAction(item._id)}>
                            Enable
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item onClick={() => onAction(item._id)}>
                            Disable
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => onDelete(item._id)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {users?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Change Platform Fee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  simpleZapperAmount: selectedUser
                    ? selectedUser.simpleZapperAmount
                    : "",
                  topZapperAmount: selectedUser
                    ? selectedUser.topZapperAmount
                    : "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    <Form.Group>
                      <Form.Label>Simple Zapper Amount</Form.Label>
                      <Field
                        type="number"
                        name="simpleZapperAmount"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="simpleZapperAmount"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Top Zapper Amount</Form.Label>
                      <Field
                        type="number"
                        name="topZapperAmount"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="topZapperAmount"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="secondary"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        className="ms-2"
                        disabled={isSubmitting}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ZapperList;

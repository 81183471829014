import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import profile from "../../images/blank-profile-picture.webp";
import { getAPI } from "../../services/User/UserService";
import Spinner from "../common/Spinner";
import { Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
function UserDetails(props) {
  const [pageCount, setPageCount] = useState(0);
  const [ratingData, setRatingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState(0);
  const limit = 10;
  const userData = props.location.state;

  useEffect(() => {
    getUserData();
  }, [currentPage]);

  const getUserData = async () => {
    setLoading(true);
    const params = {
      userId: userData._id,
      page: currentPage,
      limit: limit,
    };
    try {
      const res = await getAPI("/admin/userServices", params);
      setRatingData(res?.data?.services);
      setListLength(res?.data?.total);
      setPageCount(Math.ceil(res?.data?.total / limit));
    } catch {
      console.warn("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="page-titles">
        <h4>User Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User Details</Link>
          </li>
        </ol>
      </div>
      <Card className="w-100">
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              {/* Assuming userData.profile contains the image URL */}
              <img
                src={userData.image || profile} // Fallback to default if no profile image
                width={100}
                height={100}
                alt="Profile"
                className="text-center"
              />
            </div>
            <div className="text-left">
              <h3 className="text-black">
                {userData.name} {/* Name field */}
              </h3>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Date of Birth</h5>
              <p>{moment(userData.createdAt).format("DD/MM/YYYY")}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Email</h5>
              <p>{userData.email}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Phone Number</h5>
              <p>{userData.phoneNumber}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Address</h5>
              <p>{userData.address}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Location Coordinates</h5>
              <p>{`Longitude: ${userData.location.coordinates[0].toFixed(
                2
              )}, Latitude: ${userData.location.coordinates[1].toFixed(2)}`}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Rating</h5>
              <p>{userData.rating.toFixed(1)}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Review</h5>
              <p>{userData.reviews}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Performed Services</h5>
              <p>{userData.performedService}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Received Services</h5>
              <p>{userData.receiveService}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Account Status</h5>
              <p>{userData.status}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Top Zapper Amount</h5>
              <p>{userData.topZapperAmount}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Total Balance</h5>
              <p>{userData.totalBalance}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Stripe Status</h5>
              <p>{userData.stripe.status}</p>
            </div>

            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Last Seen</h5>
              <p>{moment(userData.lastSeen).format("DD/MM/YYYY hh:mm:ss")}</p>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="rating-details-container" style={{ marginTop: "50px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <Table responsive>
            <thead style={{ color: "black" }}>
              <tr>
                <th>Date & Time</th>
                <th>Expertise</th>
                <th>Price</th>
                <th>Duration</th>
                <th>Zapper Price</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ratingData.map((item) => (
                <tr key={item._id}>
                  <td>
                    {moment(item.dateAndTime).format("MMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td>{item.expertise.join(", ")}</td>
                  <td>{item.price}</td>
                  <td>{item.duration}</td>
                  <td>{item.zapperPrice}</td>
                  <td className="text-center">{item.feedbackUser?.rating}</td>
                  <td>{item.feedbackUser?.review || "-"}</td>
                  <td>{item.runningStatus}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {ratingData?.length === 0 && !loading && (
          <div className="justify-content-center d-flex my-5">
            Sorry, Data Not Found!
          </div>
        )}

        <div className="d-block d-sm-flex justify-content-between align-items-center">
          <div className="dataTables_info">
            Showing {currentPage * limit + 1} to{" "}
            {listLength > (currentPage + 1) * limit
              ? (currentPage + 1) * limit
              : listLength}{" "}
            of {listLength} entries
          </div>
          <Pagination
            pageCount={pageCount}
            pageValue={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default UserDetails;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const PageTitle = ({
  pageHeading,
  motherMenu,
  activeMenu,
  type,
  minRate,
  maxRate,
  setMinRate,
  setMaxRate,
}) => {
  const [userData, setUserData] = useState(null);

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === "reset") {
      setMinRate(null);
      setMaxRate(null);
    } else {
      const [min, max] = value.split("-").map(Number);
      setMinRate(min);
      setMaxRate(max);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  return (
    <div className="page-titles">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <h4>
            {activeMenu} {pageHeading}
          </h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{motherMenu}</li>
            <li className="breadcrumb-item active">{activeMenu}</li>
          </ol>
        </div>

        {/* <div className="d-flex justify-content-between mt-3">
          {(type === "zapper" || type === "user") && (
            <Form>
              <Form.Group controlId="ratingSelect">
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    setRatings(
                      e.target.value === "reset"
                        ? []
                        : [parseInt(e.target.value)]
                    )
                  }
                  value={ratings.length ? ratings[0] : ""}
                  className="rating-select"
                >
                  <option value="reset" selected>
                    All Ratings
                  </option>
                  <option value="0">0-1</option>
                  <option value="1">1-2</option>
                  <option value="2">2-3</option>
                  <option value="3">3-4</option>
                  <option value="4">4-5</option>
                </Form.Control>
              </Form.Group>
            </Form>
          )}
        </div> */}
        <div className="d-flex justify-content-between mt-3">
          {(type === "zapper" || type === "user") && (
            <Form>
              <Form.Group controlId="ratingSelect">
                <Form.Control
                  as="select"
                  onChange={handleRatingChange}
                  value={
                    minRate !== null && maxRate !== null
                      ? `${minRate}-${maxRate}`
                      : "reset"
                  }
                  className="rating-select"
                >
                  <option value="reset">All Ratings</option>
                  <option value="0-1">0 - 1</option>
                  <option value="1-2">1 - 2</option>
                  <option value="2-3">2 - 3</option>
                  <option value="3-4">3 - 4</option>
                  <option value="4-5">4 - 5</option>
                </Form.Control>
              </Form.Group>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;

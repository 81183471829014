import React, { useEffect, useState } from "react";
import "./ZapperDetails.css";
import moment from "moment";
import ShowImage from "../modal/showDocModal";
import PageTitle from "../layouts/PageTitle";
import defaultImage from "../../images/user-icon3.png";
import { getAPI } from "../../services/User/UserService";
import Spinner from "../common/Spinner";
import { Table } from "react-bootstrap";
import Pagination from "../common/Pagination";

const ZapperDetails = (props) => {
  const data = props.location.state;

  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [ratingData, setRatingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState(0);
  const limit = 10;

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };

  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }

  useEffect(() => {
    getUserData();
  }, [currentPage]); // Run useEffect again if currentPage changes

  const getUserData = async () => {
    setLoading(true);
    const params = {
      zapperId: data._id,
      page: currentPage, // Pages are 1-based, so currentPage + 1
      limit: limit,
    };
    try {
      const res = await getAPI("/admin/zapperServices", params);
      setRatingData(res?.data?.services);
      setListLength(res?.data?.total);
      setPageCount(Math.ceil(res?.data?.total / limit));
    } catch {
      console.warn("error");
    } finally {
      setLoading(false);
    }
  };
  // const getUserData = async () => {
  //   setLoading(true);
  //   const params = {
  //     zapperId: data._id,
  //     page: pageCount,
  //     limit: limit,
  //   };
  //   try {
  //     const res = await getAPI("/admin/zapperServices", params);
  //     setRatingData(res?.data?.services);
  //     const total = res?.data?.total;
  //     setPageCount(Math.ceil(total / limit));
  //   } catch {
  //     console.warn("error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <PageTitle activeMenu="Zapper Details" motherMenu="Zapper Management" />
      <div className="details-page">
        <div className="details-container">
          {/* Left Section */}
          <div className="left-section">
            <img
              src="https://v.com/100"
              alt="Profile"
              className="profile-img"
              onError={onImageError}
            />
            <h2 className="name m-0">{data?.name}</h2>
            <p className="member-since m-0">
              Last seen: {moment(data?.lastSeen).format("lll")}
            </p>
            <p className="designation m-0">
              Ratings: {data?.rating?.toFixed(1)}
            </p>
            <p className="designation m-0">{data?.type}</p>

            <div className="doc-section">
              <h3>Documents</h3>
              <div className="docs">
                {data?.documents.map((doc) => (
                  <img
                    key={doc._id}
                    className="doc-img"
                    src={doc.file}
                    onClick={() => prevImage(doc.file)}
                    onError={onImageError}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section">
            <div className="info-section">
              <h3>Official Information</h3>
              <div className="info-row pb-2">
                <div>
                  <strong>Email</strong>
                  <p>{data?.email}</p>
                </div>
                <div>
                  <strong>Phone</strong>
                  <p>
                    +{data.countryCode} {data.phoneNumber}
                  </p>
                </div>
              </div>
              <div className="full-width pb-2">
                <strong>Address</strong>
                <p>{data.address}</p>
              </div>
            </div>

            <div className="info-section">
              <h3>Personal Information</h3>
              <div className="">
                <div className="d-flex align-items-center">
                  <strong>Date Of Birth: </strong>
                  <p className="ml-2">
                    {moment(data?.dateOfBirth).format("ll")}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <strong>Receive Service: </strong>
                  <p className="ml-2">{data.receiveService}</p>
                </div>
                <div className="d-flex align-items-center">
                  <strong>Security Number: </strong>
                  <p className="ml-2">{data.securityNumber}</p>
                </div>
                <div className="d-flex align-items-center pb-2">
                  <strong>Total Balance: </strong>
                  <p className="ml-2">{data.totalBalance}</p>
                </div>
              </div>
            </div>

            <div className="tags-section">
              <h3>Expertise</h3>
              <div className="tags-container">
                {data.expertise.map((tag, idx) => (
                  <div key={idx} className="tag">
                    {tag} <span className="close-icon"></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ShowImage
          show={showImage}
          onHide={() => setShowImage(false)}
          image={selectedImage}
        />
      </div>

      <div className="rating-details-container" style={{ marginTop: "50px" }}>
        {loading ? (
          <Spinner />
        ) : (
          <Table responsive>
            <thead style={{ color: "black" }}>
              <tr>
                <th>Date & Time</th>
                <th>Expertise</th>
                <th>Price</th>
                <th>Duration</th>
                <th>Zapper Price</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ratingData.map((item) => (
                <tr key={item._id}>
                  <td>
                    {moment(item.dateAndTime).format("MMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td>{item.expertise.join(", ")}</td>
                  <td>{item.price}</td>
                  <td>{item.duration}</td>
                  <td>{item.zapperPrice}</td>
                  <td className="text-center">{item.feedbackZapper?.rating}</td>
                  <td>{item.feedbackZapper?.review || "-"}</td>
                  <td>{item.runningStatus}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {ratingData?.length === 0 && !loading && (
          <div className="justify-content-center d-flex my-5">
            Sorry, Data Not Found!
          </div>
        )}

        <div className="d-block d-sm-flex justify-content-between align-items-center">
          <div className="dataTables_info">
            Showing {currentPage * limit + 1} to{" "}
            {listLength > (currentPage + 1) * limit
              ? (currentPage + 1) * limit
              : listLength}{" "}
            of {listLength} entries
          </div>
          <Pagination
            pageCount={pageCount}
            pageValue={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default ZapperDetails;

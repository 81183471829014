import axiosInstance from "../AxiosInstance";
import {
  ADD_CODE,
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  BOOKING_GRAPH,
  DELETE_CODE,
  DELETE_USERS,
  GET_CODES,
  GET_USERS,
  POST_USER_DETAILS,
  USER_GRAPH,
  VERIFICATION_LIST,
  VERIFICATION_LIST_ACTION,
} from "./UserApiEndPoints";
// export function getAllUsers(currentPage, limit, search, type, rating) {
//   return axiosInstance.get(
//     GET_USERS +
//       `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}`
//   );
// }

// export function getAllUsers(currentPage, limit, search, type, ratings) {
//   const ratingQuery =
//     ratings && Array.isArray(ratings) && ratings.length > 0
//       ? ratings.map((rating) => `ratings[]=${rating}`).join("&")
//       : "";

//   const queryParams = `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}${
//     ratingQuery ? `&${ratingQuery}` : ""
//   }`;

//   return axiosInstance.get(GET_USERS + queryParams);
// }

export function getAllUsers(
  currentPage,
  limit,
  search,
  type,
  minRate,
  maxRate
) {
  let queryParams = `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}`;

  if (minRate !== undefined && minRate !== null) {
    queryParams += `&minRate=${minRate}`;
  }

  if (maxRate !== undefined && maxRate !== null) {
    queryParams += `&maxRate=${maxRate}`;
  }

  return axiosInstance.get(GET_USERS + queryParams);
}

export function deleteUser(id) {
  const postData = {
    userId: id,
  };
  return axiosInstance.delete(DELETE_USERS + `?userId=${id}`);
}
export function blockUser(id, actionType) {
  const postData = { userId: id, isBlocked: actionType };
  return axiosInstance.put(BLOCK_USERS, postData);
}
export function getCodes(currentPage, limit) {
  return axiosInstance.get(GET_CODES + `?page=${currentPage}&limit=${limit}`);
}
export function deleteCode(code) {
  return axiosInstance.delete(DELETE_CODE + `?code=${code}`);
}
export function addCode(code) {
  const postData = {
    codes: code,
  };
  return axiosInstance.post(ADD_CODE, postData);
}
export function getVerificationRequests(page, limit, search) {
  return axiosInstance.get(
    VERIFICATION_LIST + `?page=${page}&limit=${limit}&search=${search}`
  );
}
export function approveRejectRequest(formData) {
  return axiosInstance.put(VERIFICATION_LIST_ACTION, formData);
}
export function getGraphData(type) {
  return axiosInstance.get(BOOKING_GRAPH + `?timePeriod=${type}`);
}
export function getUserGraphData(type) {
  return axiosInstance.get(USER_GRAPH + `?timePeriod=${type}`);
}
export function getRequestPercentageApi() {
  return axiosInstance.get("admin/calculateExpertisePercentage");
}

export const getAPI = (endpoint, params) => {
  return axiosInstance.get(endpoint, { params }).then((res) => res.data);
};

export const postAPI = (endpoint, payload) => {
  return axiosInstance.post(endpoint, payload).then((res) => res.data);
};

export const putAPI = (endpoint, payload) => {
  return axiosInstance.put(endpoint, payload).then((res) => res.data);
};
export const deleteAPI = (endpoint, params) => {
  return axiosInstance.delete(endpoint, { params }).then((res) => res.data);
};

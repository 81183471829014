import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import ZapperList from "./ZapperList";
import RequestList from "./RequestList";

export default function ZapperManagement(props) {
  const [selectedTab, setSelectedTab] = useState("requests");
  const [minRate, setMinRate] = useState(null);
  const [maxRate, setMaxRate] = useState(null);

  const tabData = [
    {
      name: "Requests",
      component: <RequestList minRate={minRate} maxRate={maxRate} />,
    },
    {
      name: "Zapper",
      component: <ZapperList minRate={minRate} maxRate={maxRate} />,
    },
  ];

  return (
    <div>
      <PageTitle
        activeMenu="Zapper List"
        motherMenu="Zappers"
        type="zapper"
        minRate={minRate}
        maxRate={maxRate}
        setMinRate={setMinRate}
        setMaxRate={setMaxRate}
      />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data?.name.toLowerCase()}
                    onClick={() => setSelectedTab(data.name.toLowerCase())}
                    key={i}
                  >
                    {data?.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
